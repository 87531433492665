@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap');

$navabr-bg: #202428;
$navlink-clr: #f2f1f1;

.nft {
  .nft-bg {
    background-image: url('../../images/section-background.jpg');
    background-size: cover;
    background-position: bottom;
    /*background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(33, 70, 104, 1) 50%, rgba(12, 28, 49, 1) 100%);*/

    .nft-header {
      .nft-title {
        .row {
          width: 100%;
          margin: 0 auto;
          align-items: center;
          min-height: 300px;
        }
      }
     
      h2 {
        font-family: 'D-DIN Exp';
        font-weight: bold;
        font-size: 2.5rem;
      }
      p {
        font-family: 'Poppins', sans-serif;
      }
      .nft-cubo {
        margin-top: -50px;
        position: absolute;
        height: 450px;
      }
    }
  }
  .nft-content {
      iframe {
          margin: 100px auto;
        width: 100%;
        min-height: 500px;
      }
      h1,h2,h3,h4,h5 {
        font-family: 'D-DIN Exp';
        font-weight: bold;
      }
      
      p {
        font-family: 'Poppins', sans-serif;
      }
      p a {
        color: #727272;
        font-family: 'Poppins', sans-serif;
        text-decoration: none !important;

        .link-arrow {
            position: relative;
            font-weight: bold;
            font-size: 1.2rem;
            top: .2rem;
            height: 20px;
        }
      }
      .nft-icon {
          height: 150px;
          
      }
  }
  @media (max-width: 768px) {
    .nft-bg{
      .nft-header{
        .row.my-5{
          width: 100%;
          margin: 0 auto!important;
          .col-6{
            padding: 0;
            height: auto;
          }
          .nft-title{
            align-items: center;
            display: flex;
            .row{
              min-height: unset;
              h2{
                font-size: 18px;
              }
            }
          }
          .edge-round-bg{
            img{
              width: 100%;
              margin: 0;
              position: relative;
              height: auto;
            }
          }
        }
      }
      
    }
    .nft-content {
      iframe{
        margin: 40px auto;
      }
			.row {
				width: 100%;
				margin: 0 auto;
				.col-12 {
          padding: 0;
					h2,h3 {
						font-size: 22px; 
					}
          p{
            font-size: 16px;
          }
				}
			}
		}
  }
}
