

$navabr-bg: #202428;
$navlink-clr: #f2f1f1;

.other {
  .other-bg {
    background-color: lightgray;
    /*background-image: url("../../images/banner.jpg");
    background-size: cover;
    height: 280px;
    width: 100%;*/
    .other-content {
      h2 {
        padding-top: 50px;
        font-family: "D-DIN Exp";
        font-weight: bold;
        font-size: 2.1rem;
      }
      img {
        height: 380px;
      }
    }
  }
  @media (max-width: 768px) {
    .other-content{
      .row{
        width: 100%;
        margin: 0 auto;
        .col-12{
          padding: 0;
          h2{
            padding-top: 0;
            font-size: 22px;
          }
          a{
            width: 100%;
            img{
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}
