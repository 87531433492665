$navabr-bg: #202428;
$navlink-clr: #f2f1f1;

.banner {

  .banner-bg {
    background-image: url('../../images/header_background.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    .banner-content {
      h2 {
        color: $navlink-clr;
        padding-top: 50px;
        font-family: 'D-DIN Exp';
        font-weight: bold;
        font-size: 40px;
      }
      p {
        color: #45ef8e;
        font-family: 'D-DIN Exp';
        font-size: 1.2rem;
      }
    }
  }
  @media (max-width: 768px) {
    .banner-bg{
      .banner-content{
        .row{
          width: 100%;
          margin:0 auto;
          flex-direction: column-reverse;
           .silver-gorilla{
            padding: 0;
            img{
              width: 100%;
              height: auto;
              margin: 0 !important;
            }
           }
           .main-content{
            margin-top: 0!important;
            padding: 0!important;
            margin-bottom: 70px!important;
            h2{
              padding-top: 0;
              font-size: 22px;
            }
            p{
              font-size: 16px;
            }
           }
        }
      }
    }
  }
}
