.nft-tabs {
	.tabs {
		color: white;
		background-color: #204356;
		.tab-list {
			background-color: #163244;
			.active-tab {
				background-color: #204356;
			}

			.tab-header {
				&:hover {
					cursor: pointer;
				}
			}
		}
		.tab-content {
			.data {
				color: #5eeebf;
			}
			.product-info-data {
				@media (max-width: 991.98px) {
					&:nth-of-type(2n + 1) {
						background-color: #163244;
					}
					&:last-child {
						background-color: #204356;
					}
				}

				// Large devices (desktops, 992px and up)
				@media (min-width: 992px) and (max-width: 1199.98px) {
					&:nth-child(4n + 1),
					&:nth-child(4n + 2) {
						background-color: #163244;
					}
					&:last-child {
						background-color: #204356;
					}
				}

				// Extra large devices (large desktops, 1200px and up)
				@media (min-width: 1200px) {
					&:nth-child(6n + 1),
					&:nth-child(6n + 2),
					&:nth-child(6n + 3) {
						background-color: #163244;
					}
				}
			}
			.blockchange-info {
				.product-info-data {
					// Large devices (desktops, 992px and up)
					&:nth-child(2n + 1) {
						background-color: #163244;
					}
					&:last-child {
						background-color: #204356;
					}

					@media (min-width: 1200px) {
						&:nth-child(6n + 2),
						&:nth-child(6n + 4) {
							background-color: unset;
						}
					}
				}
			}
			.btn-nft-action {
				width: 200px;
			}
			.my-nfts {
				.nft-item {
					cursor: pointer;
					&:nth-child(2n) {
						background-color: #163244;
					}
					input,
					label {
						cursor: pointer;
						font-family: "D-DIN Exp";
					}
					.form-check-input[disabled] ~ .form-check-label,
					.form-check-input:disabled ~ .form-check-label {
						color: white;
					}
					&.claimed {
						opacity: 0.4;
					}

					.btn-view {
						position: absolute;
						left: 80%;
						padding: 3px 16px;
						top: 0px;
					}
					.showIcon {
						position: absolute;
						right: 10px;
						top: 0px;
						background-color: transparent;
						color: white;
						transition: all 0.5s ease-in-out;
					}
					.showIcon:hover {
						color: #45ef8e;
						transform: scale(1.3);
					}
				}
				.totalEarner {
					background-color: #163244;
				}
			}
			@media (max-width: 768px) {
				.col-lg-9.col-12,.col-lg-3.col-12.text-center.my-5 {
					margin-top: 30px !important;
					.col-12.px-4.pl-5.py-2 {
						.col-8.d-flex.justify-content-left.align-self-center.pl-5,
						.col-4.d-flex.justify-content-center {
							padding: 10px !important;
						}
					}
					.col-4.d-flex.align-self-center.justify-content-center {
						align-self: flex-start !important;
					}
				}
				.col-12.px-4.pl-5.py-2,
				.col-6.d-flex.justify-content-left.align-self-center.pl-5.py-2 {
					padding: 10px !important;
          .btn-nft-action{
            width: 90%!important;
          }
        }
        .col-lg-3.col-12.text-center{
          margin-bottom: 20px!important;
          
        }
        
			}
		}
	}
	@media (max-width: 768px) {
		.tabs {
			width: 100%;
			margin: 0 auto;
			.tab-list {
				// overflow-x: scroll;
				.d-flex.justify-content-center {
					flex-direction: column;
				}
				width: 100%;
				.tab-header {
					font-size: 12px;
					padding-bottom: 8px;
					img {
						margin: 0 auto;
						max-width: 30px;
						height: auto;
					}
				}
			}

			.tab-content {
				.row {
					width: 100%;
					margin: 0 auto;
					.col-12 {
						padding: 0px !important;
						span {
							word-wrap: break-word;
							&:first-child {
								padding-left: 0 !important;
							}
							&.data {
								text-align: right;
							}
						}
					}
				}
			}
		}
	}
}
