@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");

$navabr-bg: #202428;
$navlink-clr: #f2f1f1;

.certificate {
	.certificate-bg {
		background-image: url("../../images/section-background.jpg");
		background-size: cover;
		background-position: bottom;
		/*background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(33, 70, 104, 1) 50%, rgba(12, 28, 49, 1) 100%);*/

		.certificate-header {
			& > .row {
				width: 100%;
				margin: 0 auto;
				padding: 40px 0;
			}
			.certificate-title {
				.row {
					min-height: 300px;
				}
			}

			h2 {
				font-family: "D-DIN Exp";
				font-weight: bold;
				font-size: 2.5rem;
			}
			p {
				font-family: "D-DIN Exp";
			}
			.certificate-tarjeta {
				max-height: 300px;
			}
		}
	}
	.certificate-content {
		iframe {
			margin: 100px auto;
			width: 100%;
			min-height: 500px;
		}
		h3 {
			font-family: "D-DIN Exp";
			font-weight: bold;
			font-size: 2rem;
		}
		h2 {
			font-family: "D-DIN Exp";
			font-weight: bold;
			font-size: 2.5rem;
		}
		p {
			font-family: "Poppins", sans-serif;
			text-align: justify;
		}
		p a {
			color: #727272;
			font-family: "Poppins", sans-serif;
			text-decoration: none !important;

			.link-arrow {
				position: relative;
				font-weight: bold;
				font-size: 1.2rem;
				top: 0.2rem;
				height: 20px;
			}
		}
		.certificate-icon {
			height: 150px;
		}
		.certificate-img01 {
			height: 200px;
			-webkit-box-shadow: -5px 5px 15px 0px #000000;
			box-shadow: -5px 5px 15px 0px #000000;
		}
		.certificate-img02 {
			height: 450px;
		}
		.certificate-img03 {
			height: 300px;
		}
	}
	@media (max-width: 768px) {
    .certificate-bg{
      .certificate-header{
        .row.my-5{
          flex-direction: row-reverse;
          width: 100%;
          margin: 0 auto!important;
          .col-6{
            padding: 0;
            height: auto;
          }
          .certificate-title{
            align-items: center;
            display: flex;
            .row{
              min-height: unset;
              width: 100%;
              margin: 0 auto;
              h2{
                font-size: 18px;
              }
            }
          }
          .edge-round-bg{
            img{
              width: 100%;
              margin: 0;
              position: relative;
              height: auto;
            }
          }
        }
      }
    }
		.row {
			.col-lg-6 {
				h2,
				h3 {
					font-size: 22px;
				}
				& > img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}
