

$navabr-bg: #202428;
$navlink-clr: #f2f1f1;
$gray-clr: #c9c9c9;
$green: #00dd73;
$white: #ffffff;
$dark: #000000;
$gold: #f6aa18;

$DN-Exp: "D-DIN Exp";
$popins: "Poppins", sans-serif;

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.buy {
  .buy-bg {
    /*background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(33, 70, 104, 1) 50%, rgba(12, 28, 49, 1) 100%);*/
    background-image: url('../../images/header_background.jpg');
    background-size: cover;
    background-position: center;
    .buy-blur-text {
      text-align: center;
      position: absolute;
      h1 {
        font-size: 90px;
        font-weight: bold;
      }
    }

    .buy-content {
      padding: 40px 0px 0px 0px;
      h2 {
        text-align: center;
        font-size: 40px;
        color: $navlink-clr;
        font-family: $DN-Exp;
        span {
          color: $green;
        }
      }
      p {
        font-size: 15px;
        font-family: $popins;
        color: #97afd5;
        text-align: center;
        margin-bottom: 0px;
      }
    }
    .buy-grid {
      .heading {
        h5 {
          color: $green;
          font-size: 17px;
          font-family: $DN-Exp;
          @media screen and (max-width: "1024px") {
            font-size: 15px;
          }
          @media screen and (max-width: "767px") {
            padding: 17px 0px;
          }
        }
        p {
          color: $white;
          width: 300px;
          font-family: $DN-Exp;
          padding-left: 48px;
          @media screen and (max-width: "1024px") {
            font-size: 14px;
            width: 250px;
          }
          @media screen and (max-width: "768px") {
            font-size: 14px;
            width: 200px;
          }
        }
      }
      .list {
        margin-left: 50px;

        .list-image {
          .media {
            .media-body {
              h5 {
                font-size: 17px;
                font-family: $DN-Exp;
                color: $gold;
              }
              ul {
                list-style: none; /* Remove default bullets */
                list-style-position: outside;

                li {
                  color: $gray-clr;
                  @media screen and (max-width: "768px") {
                    font-size: 14px;
                  }
                  &::before {
                    content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
                    color: $gold; /* Change the color */
                    font-weight: bold; /* If you want it to be bold */
                    display: inline-block; /* Needed to add space between the bullet and the text */
                    width: 1em; /* Also needed for space (tweak if needed) */
                    margin-left: 1em; /* Also needed for space (tweak if needed) */
                  }
                }
              }
            }
          }
        }
      }

      .heading {
        h5 {
          color: $green;
          font-size: 17px;
          font-family: $DN-Exp;
          margin-left: 20px;
        }

        p {
          font-size: 13px;
          color: $gray-clr;
        }
      }
      iframe{
        width: 100%;
        min-height: 646px;
      }
    }
    .images {
      .ml-61 {
        margin-left: 60px;
      }
    }

    .buy-btn {
      text-align: center;
      padding-bottom: 50px;
      .btn {
        text-align: center;
        color: $white;
        font-family: $DN-Exp;
        border: 1px solid $green;
      }
    }
  }
  @media (max-width: 768px) {
    .buy-bg{
      .row{
        width: 100%;
        margin: 0 auto;
      }
      .buy-content{
        padding:40px 0;
        h2{
          font-size: 22px;
        }
      }
    }
  }
}



// .buy {
//   .buy-bg {
//     background-color: #161d20;
//     // .buy-blur-text {
//     //   text-align: center;
//     //   position: absolute;
//     //   h1 {
//     //     font-size: 90px;
//     //     font-weight: bold;
//     //   }
//     // }
//     .buy-content {
//       padding: 40px 0px 0px 0px;
//       h2 {
//         text-align: center;
//         font-size: 40px;
//         color: $navlink-clr;
//         font-family: $DN-Exp;
//         span {
//           color: $green;
//         }
//       }
//       p {
//         font-size: 15px;
//         font-family: $popins;
//         color: #97afd5;
//         text-align: center;
//       }
//     }
//     .buy-grid {
//       overflow-x: hidden;
//       .heading {
//         h2 {
//           color: $green;
//           font-size: 17px;
//           font-family: $DN-Exp;
//           @media screen and (max-width: "1024px") {
//             font-size: 15px;
//           }
//         }
//         p {
//           color: $white;
//           width: 300px;
//           font-family: $popins;
//           margin-left: 17px;
//           @media screen and (max-width: "1024px") {
//             font-size: 14px;
//             width: 250px;
//           }
//           @media screen and (max-width: "768px") {
//             font-size: 14px;
//             width: 200px;
//           }
//         }
//       }
//       .list {
//         margin-left: 36px;
//         // @media screen and (max-width: "1024px") {
//         //   margin-left: 50px;
//         // }
//         // @media screen and (max-width: "768px") {
//         //   margin-left: 10px;
//         // }
//         // @media screen and (max-width: "425px") {
//         //   margin-left: 102px;
//         // }
//         // @media screen and (max-width: "375px") {
//         //   margin-left: 60px;
//         // }
//         .list-image {
//           .media {
//             .media-body {
//               h5 {
//                 font-size: 17px;
//                 font-family: $DN-Exp;
//                 color: $gold;
//               }
//               ul {
//                 list-style: none; /* Remove default bullets */

//                 li {
//                   color: $white;
//                   @media screen and (max-width: "768px") {
//                     font-size: 14px;
//                   }
//                   &::before {
//                     content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
//                     color: $gold; /* Change the color */
//                     font-weight: bold; /* If you want it to be bold */
//                     display: inline-block; /* Needed to add space between the bullet and the text */
//                     width: 1em; /* Also needed for space (tweak if needed) */
//                     margin-left: 1em; /* Also needed for space (tweak if needed) */
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//       .heading {
//         h5 {
//           color: $green;
//           font-size: 17px;
//           font-family: $DN-Exp;
//           margin-left: 20px;
//           // @media screen and (max-width: "1024px") {
//           //   font-size: 15px;
//           //   margin-left: 40px;
//           // }
//           // @media screen and (max-width: "768px") {
//           //   margin-left: 20px;
//           // }
//           // @media screen and (max-width: "425px") {
//           //   margin-left: 103px;
//           // }
//           // @media screen and (max-width: "375px") {
//           //   margin-left: 80px;
//           // }
//           // @media screen and (max-width: "320px") {
//           //   margin-left: 50px;
//           // }
//         }
//       }
//       .mt-100 {
//         margin-top: 100px;
//         @media screen and (max-width: "425px") {
//           margin-top: 40px;
//         }
//       }
//       .mt-xl-120 {
//         margin-top: 171px;
//         @media screen and (max-width: " 992px") {
//           margin-top: 101px !important;
//         }
//         // @media screen and (max-width: "992px") {
//         //   margin-top: 10px;
//         // }
//         // @media screen and (max-width: "768px") {
//         //   margin-top: 100px;
//         // }
//         // @media screen and (max-width: "425px") {
//         //   margin-top: 30px;
//         // }
//       }
//       //   .mr-100{
//       //       marg
//       //   }

//       .images {
//         .ml-61 {
//           margin-left: 61px;
//         }
//       }
//     }
//     .buy-btn {
//       text-align: center;
//       padding-bottom: 50px;
//       .btn {
//         text-align: center;
//         color: $white;
//         font-family: $DN-Exp;
//         border: 1px solid $green;
//       }
//     }
//   }
// }
