

$navabr-bg: #202428;
$navlink-clr: #f2f1f1;
$green: #00dd73;
$white: #ffffff;
$dark: #000000;
$gold: #f6aa18;

$DN-Exp: "D-DIN Exp";
$popins: "Poppins", sans-serif;

.modal-backdrop {
  position: unset !important;
}
.modal-dailog {
  transform: translate(0, 50%) !important;
}
.modal-backdrop {
  background-color: green;
}
// .modal-open {
//   background-color:#bf6363;
//   top:0;
//   left:0;
//   position:absolute;
//   width:100%;
//   height:100%;
//   filter:opacity(0.5);
//   filter:blur(20px);
// }
.modal-section-one {
  .modal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          .modal-title {
            font-size: 20px;
            color: $dark;
          }
        }
        .modal-body {
          p {
            font-size: 15px;
            font-family: $popins;
            color: #6e7e98;
          }

          input {
            width: 60%;
          }
          small {
            font-size: 12px;
            color: #6e7e98;
            b {
              font-size: 12px;
              color: #868e96;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
.modal-section-Two {
  .modal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          .modal-title {
            font-size: 20px;
            color: $dark;
          }
        }
        .modal-body {
          p {
            font-size: 15px;
            font-family: $popins;
            color: #6e7e98;
          }

          input {
            width: 60%;
          }
          small {
            font-size: 12px;
            color: #6e7e98;
            b {
              font-size: 12px;
              color: #868e96;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
.modal-section-three {
  .modal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          .modal-title {
            font-size: 20px;
            color: $dark;
          }
        }
        .modal-body {
          p {
            font-size: 15px;
            font-family: $popins;
            color: #6e7e98;
          }

          input {
            width: 60%;
          }
          small {
            font-size: 12px;
            color: #6e7e98;
            b {
              font-size: 12px;
              color: #868e96;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: red;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: red;
  }
}
.modal-section-four {
  .modal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          .modal-title {
            font-size: 20px;
            color: $dark;
          }
        }
        .modal-body {
          p {
            font-size: 15px;
            font-family: $popins;
            color: #6e7e98;
          }

          .button-border {
            border: 1px solid #e0e0e0;
            border-radius: 5px;
          }
        }
      }
    }
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: red;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: red;
  }
}
.modal-section-five {
  .modal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          .modal-title {
            font-size: 20px;
            color: $dark;
          }
        }
        .modal-body {
          p {
            font-size: 15px;
            font-family: $popins;
            color: $green;
          }

          .button-border {
            border: 1px solid #e0e0e0;
            border-radius: 5px;
          }
        }
      }
    }
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #3999fa;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: red;
  }
}
.modal-section-six {
  .modal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          .modal-title {
            font-size: 20px;
            color: $dark;
          }
        }
        .modal-body {
          p {
            font-size: 15px;
            font-family: $popins;
            color: #6e7e98;
          }

          input {
            width: 60%;
          }
          small {
            font-size: 12px;
            color: #6e7e98;
            b {
              font-size: 12px;
              color: #868e96;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: red;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: red;
  }
}

.blockchain-connect {
  cursor: pointer;
}