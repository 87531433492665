

$navabr-bg: #202428;
$navlink-clr: #f2f1f1;

.history {
  .history-bg {
    background-color: white;
    /*background-image: url("../../images/banner.jpg");
    background-size: cover;
    height: 280px;
    width: 100%;*/
    .history-content {
      h2 {
          
        // padding-top: 50px;
        font-family: "D-DIN Exp";
        font-weight: bold;
        font-size: 2.5rem;
      }
      p {
        color: #727272;
        font-family: "D-DIN Exp";
        text-align: justify;
      }
      img {
        height: 360px;
      }
    }
  }
  @media (max-width: 768px) {
      .history-content{
        .row{
          width: 100%;
          margin: 0 auto;
          .col-lg-6{
            padding: 0;
            img{
              width: 88%;
              height: auto;
          }
          }
          .col-lg-5{
            padding: 0;
            margin-top: 20px!important;
            h2{
              font-size: 22px;
            }
          }
        }
      }
  }
}
